@charset "utf-8";

//-------------------- l-header
//-------------------- l-wrap, l-contents, l-section, l-inner
//-------------------- l-width

//-------------------- l-page-title
//-------------------- l-bread
//-------------------- l-column
//-------------------- l-news

//-------------------- l-pagetop
//-------------------- l-contact
//-------------------- l-footer


//-------------------- l-header
.l-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	z-index: 9;
	@include pc {height: 80px;}
	@include tbl {height: 65px;}
	@include sp {height: 13vw;}
	body.is-contact & {position: absolute!important;}
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 50px;
		// box-shadow: 0 0.15rem 0.5rem rgba(0,0,0,0.15);
		position: relative;
		@include tblsp {
			height: 100%;
		}
		@include tbl {
			padding: 0 0 0 30px;
		}
		@include sp {
			padding: 0 0 0 5vw;
		}
	}
}
// IE11 over write
@media all and (min-width: 1024px) and (-ms-high-contrast: none) {
}
.l-header-logo {
	@include pc {width: 140px;}
	@include tbl {width: 120px;}
	@include sp {width: 23.5vw;}
}
.l-header-nav-wrap {
	@include pc {
		display: flex!important;
		align-items: center;
		margin-left: auto;
		margin-right: 0;
	}
	@include tblsp {
		display: none;
		background-color: #fff;
		padding-top: 0.4rem;
		box-shadow: 0 0.6rem 0.5rem -0.5rem rgba(0,0,0,0.15) inset;
		overflow: scroll;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 2;
	}
	@include tbl {
		top: 65px;
		height: calc(100vh - 60px);
	}
	@include sp {
		top: 13vw;
		height: calc(100vh - 13vw);
	}
}
.l-header-nav {
	@include fs($_FONT_SIZE_HEADER);
	font-weight: bold;
	line-height: $_LINE_HEIGHT;
	@include pc {display: flex;}
	@include tblsp {letter-spacing: 0.1em;}
	@include tbl {
		@include fs($_FONT_SIZE_HEADER_TB);
	}
	@include sp {
		@include vw($_FONT_SIZE_HEADER_SP);
	}
	&__item {
		white-space: nowrap;
		@include tblsp {
			border-bottom: 1px solid $_BORDER_COLOR_HEADER;
		}
		&.is-hasChild {
			&:hover {
				@include pc {
					.l-header-nav-child {
						height: 80px;
						z-index: 7;
					}
				}
			}
		}
	}
}
.l-header-nav-parent {
	position: relative;
	color: $_COLOR_HEADER;
		@include pc {
		display: flex;
		align-items: center;
		height: 80px;
		padding: 1rem 1.75rem;
		cursor: pointer;
		transition: 0.25s;
		@include fs($_FONT_SIZE_HEADER);
		.is-hasChild & {
			padding-right: 2rem;
			&::after {
				content: "";
				display: inline-block;
				width: 0.5em;
				height: 0.5em;
				border-right: 1px solid $_COLOR_HEADER_ARROW;
				border-bottom: 1px solid $_COLOR_HEADER_ARROW;
				transform: rotate(45deg);
				margin: -0.25em  0 0 1em;
				transition: 0.25s;
			}
		}
		.l-header-nav__item:hover & {
			background-color: $_COLOR_HEADER;
			color: $_COLOR_HEADER_INVERSION;
			&::after {
				border-right-color: $_COLOR_HEADER_INVERSION;
				border-bottom-color: $_COLOR_HEADER_INVERSION;
			}
		}
	}
	@include tblsp {
		display: block;
		font-weight: bold;
		&.is-link {
			&::after {
				content: "";
				display: inline-block;
				width: 0.5em;
				height: 0.5em;
				border-top: 1px solid $_COLOR_HEADER;
				border-right: 1px solid $_COLOR_HEADER;
				transform: rotate(45deg);
				position: absolute;
				top: 1.15em;
				right: 40px;
				@include sp {right: 5vw;}
			}
		}
	}
	@include tbl {
		@include fs($_FONT_SIZE_HEADER_TB);
		padding: 0.65rem 50px;
	}
	@include sp {
		@include vw($_FONT_SIZE_HEADER_SP);
		padding: 0.5rem 5.0vw;
	}
	// &::before {
	// 	@include pc {transition: 0.25s;
	// 		.l-header-nav__item:not(:last-child) & {
	// 			content: "";
	// 			display: block;
	// 			position: absolute;
	// 			right: 0;
	// 			top: 50%;
	// 			height: 1em;
	// 			border-right: 1px solid #8192a3;
	// 			transform: translateY(-50%);
	// 		}
	// 	}
	// }
}
.l-header-nav-child {
	@include pc {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $_COLOR_HEADER;
		transform: translateY(99%);
		overflow: hidden;
		transition: 0.15s;
	}
	a {
		display: inline-block;
		color: $_COLOR_HEADER_INVERSION;
		@include fs($_FONT_SIZE_HEADER_CHILD);
		@include pc {
			padding: 1rem;
			position: relative;
			&:hover {text-decoration: underline;}
		}
		@include tblsp {
			display: block;
			color: $_COLOR_HEADER_CHILD;
			position: relative;
			font-weight: $_FONT_WEIGHT;
		}
		@include tbl {
			@include fs($_FONT_SIZE_HEADER_CHILD_TB);
			padding: 0.65rem 50px 0.65rem 68px;
		}
		@include sp {
			@include vw($_FONT_SIZE_HEADER_CHILD_SP);
			padding: 0.5rem 7.5vw 0.5rem 10vw;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 0.5em;
			height: 0.5em;
			border-top: 1px solid #fff;
			border-right: 1px solid #fff;
			transform: rotate(45deg);
			@include pc {margin: -0.3em  0 0 0.75em;}
			@include tblsp {
				border-top-color: $_COLOR_HEADER_ARROW;
				border-right-color: $_COLOR_HEADER_ARROW;
				position: absolute;
				top: 1.15em;
				right: 40px;
			}
			@include sp {right: 5vw;}
		}
	}
	&__item {
		@include tblsp {
			border-top: 1px solid $_BORDER_COLOR_HEADER;
		}
		&+& {
			@include pc {margin-left: 2rem;}
		}
	}
}


.l-header-btn {
	margin-left: 1.0rem;
	@include pcXS {
		margin-left: 0.6rem;
	}
	@include tblsp {
		margin-left: auto;
		height: 100%;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $_BG_COLOR_CONVERSION;
		@include pc {
			padding: 1.0em;
			width: 150px;
		}

		@include tblsp {
			width: 4rem;
			height: 100%;
		}
		@include sp {
			width: 3.0rem;
		}
	}

	&__txt {
		display: block;
		width: 100%;
		padding: 0 1.0em;
		text-align: center;
		position: relative;
		color: #fff;
		font-family: $_FONT_FAMILY_EN;
		font-weight: $_FONT_WEIGHT_BOLD;
		font-size: 20px;
		letter-spacing: 0.1em;

		@include pc {
		}
		@include tblsp {
			padding: 0 0.2em;
			font-size: 16px;
		}
		@include sp {
			@include vw(10);
		}
	}
}

.l-header-nav-sp {
	display: none;
	@include tblsp {
		display: block;
		height: 100%;
		position: relative;
		.is-navOpen & {
		}
		width: 4.0rem;
	}
	@include sp {
		width: 3.0rem;
	}
	&-icon {
		display: block;
		position: absolute;
		width: 50%;
		height: 40%;
		margin: 0 auto;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		@include sp {
			// top: 35%;
		}
		&__line {
			position: absolute;
			height: 3px;
			background-color: $_COLOR_HEADER;
			width: 100%;
			left: 0;
			top: 50%;
			transform: translate3d(0, -50%, 0);
			.is-navOpen & {
				opacity: 0;
			}
		}
		&:before, &:after {
			content: "";
			height: 3px;
			background-color: $_COLOR_HEADER;
			width: 100%;
			position: absolute;
		}
		&:before {
			left: 0;
			top: 0;
			.is-navOpen & {
				top: 50%;
				left: 50%;
				// width: 50%;
				transform: translate3d(-50%, -50%, 0) rotate(45deg);
			}
		}
		&:after {
			left: 0;
			bottom: 0;
			width: 70%;
			.is-navOpen & {
				top: 50%;
				left: 50%;
				width: 100%;
				transform: translate3d(-50%, -50%, 0) rotate(-45deg);
			}
		}
	}
	&-txt {
		position: absolute;
		bottom: 8%;
		width: 100%;
		text-align: center;
		font-family: $_FONT_FAMILY_EN;
		font-size: 12px;
		letter-spacing: 0.1em;
		line-height: 1.0;
		transform: translate3d(0, -50%, 0);
		@include sp {
			@include vw(8);
		}
		&__default {
			.is-navOpen & {
				display: none;
			}
		}
		&__close {
			display: none;
			.is-navOpen & {
				display: block;
			}
		}
	}
}

.l-header-nav-close {
	@include pc {display: none;}
	@include tblsp {
		position: relative;
		width: 1.5rem;
		height: 1.5rem;
		margin: 5rem auto 0;
	}
	@include tbl {
	}
	@include sp {
		// position: absolute;
		// left: 50%;
		// bottom: 3rem;
		// margin-left: -0.75rem;
	}
	&:before, &:after {
		content: "";
		height: 3px;
		background-color: $_COLOR_HEADER;
		width: 100%;
		position: absolute;
	}
	&:before {
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0) rotate(45deg);
	}
	&:after {
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0) rotate(-45deg);
	}
}

//-------------------- l-wrap,  l-main,l-contents, l-section, l-inner

.l-wrap {
	padding-top: 80px;
	overflow: hidden;
	@include tbl {padding-top: 65px;}
	@include sp {padding-top: 13vw;}
	@include pc {
		&::after {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 0;
			background-color: rgba(255,255,255,0.65);
			z-index: -1;
			transition: 0.25s;
		}
		&.is-navOpen {
			&::after {
				height: 120vh;
				z-index: 6;
			}
		}
	}
}

.l-main {
}
.l-section {
	padding: 6.0rem 0 6.5rem;
	@include sp {padding: 5.0rem 0 5.5rem;}
	&:last-child:not(.is-bgG):not(.top-recruit) {padding-bottom: 0;}
	&.is-bgG {
		background-color: #EDF0F7;
		.l-inner + & {
			margin-top: 6.0rem;
			@include sp {
				margin-top: 5.0rem;
			}
		}
	}
	.l-page-title.is-noBg + & {padding-top: 0;}
	&:not(.is-bgG) + &:not(.is-bgG) {padding-top: 0;}
	&.is-bgG + &.is-bgG {padding-top: 0;}
}

.l-inner {
	@include inner;
	&.is-M {
		max-width: 1200px;
	}
	&.is-S {
		max-width: 1100px;
	}
	&.is-XS {
		max-width: 1000px;
		// @include tbl {width: 90%;}
	}
	&.is-blankS {margin-top: 3rem;}
	&.is-blankM {margin-top: 4.5rem;}
	&.is-blankL {margin-top: 6rem;}

	.l-page-title + & {
		@include pc {margin-top: 5rem;}
		@include tbl {margin-top: 4rem;}
		@include sp {margin-top: 2.5rem;}
	}
}

//-------------------- l-page-title

.l-page-title {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	width: 100%;
	height: 280px;
	position: relative;
	@include tbl {height: 280px;}
	@include sp {height: 46.666667vw;}
	&.is-noBg {
		color: $_COLOR_HEADLINE;
		height: auto;
		padding: 3.5rem 0 4rem;
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
// IE11 over write
@media all and (-ms-high-contrast: none) {
	.l-page-title__bg {
		overflow: hidden;
		img {
			width: auto;
			height: auto;
			max-width: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

//-------------------- l-bread

.l-bread {
	@include inner;
	display: flex;
	color: #333;
	@include fs(12);
	line-height: $_LINE_HEIGHT_XS;
	max-width: 1800px;
	padding-top: 1rem;
	@include sp {
		@include vw(12);
		white-space: nowrap;
		overflow: auto;
	}
	&:not(:first-child) {
		@include pc {margin-bottom: 5rem;}
		@include tbl {margin-bottom: 4rem;}
		@include sp {margin-bottom: 2.5rem;}
	}
	a {
		color: #ababab;
		transition: 0.25s;
		&:hover {
			@include pc {
				color: #333;
				text-decoration: underline;
			}
		}
	}
	&__item {
		&:not(:empty):not(:last-child)::after {
			content: ">";
			display: inline-block;
			color: #ababab;;
			margin: 0 0.5em;
			@include sp {margin: 0 0.25em;}
		}
		&:last-child {
			@include tblsp {padding-right: 1em;}
		}
	}
}


//-------------------- l-column

.l-column {
	@include pctbl {
		display: flex;
		justify-content: space-between;
	}
	&:not(:first-child) {
		margin-top: 3.5rem;
		@include sp {margin-top: 2rem;}
	}
	&+& {
		margin-top: 3rem;
		@include sp {margin-top: 1.2rem;}
	}
	&.is-col1 {
		@include pctbl {justify-content: center;}
	}
	&.is-col2 {
		flex-wrap: wrap;
		&.is-tbl-release {
			@include tbl {display: block;}
		}
	}
	&.is-col3 {
		flex-wrap: wrap;
	}
	&.is-col4 {
		flex-wrap: wrap;
		@include pc {justify-content: flex-start;}
		@include tblsp {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
	&.is-button-set {
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
	}
	&__item {
		max-width: 720px;
		@include pctbl {
			.is-col1 & {width: 48.2%;}
			.is-col2 & {flex: 0 0 46.6%;}
			.is-col3 & {flex: 0 0 32%;}
			.is-col4 & {flex: 0 0 23.5%;}
		}
		@include pc {
			.is-col4 &+& {margin-left: 2%;}
		}
		@include tbl {
			//カラム・非カラムコントロール
			.is-tbl-release & {max-width: 100%;}
			.is-tbl-release &+& {
				margin-top: 3.5rem;
			}
		}
		@include tblsp {
			.is-col4 & {
				@include pc {width: 48.5%;} //overflowバグ flex不可
				@include tblsp {flex: 0 0 48%;}
				&:nth-child(2) {margin-top: 0;}
				&:nth-child(n+3) {margin-top: 1.25rem;}
				img {width: 100%;}
			}
		}
		@include sp {
			&+& {margin-top: 1.2rem;}
			.is-sp-col2 & {
				width: 48%;
				&:nth-child(2) {margin-top: 0;}
				&:nth-child(n+3) {margin-top: 2.5rem;}
			}
		}
	}
}



//-------------------- l-news

.l-news-wrap {
	.top-news & {
		@include pc {
			margin-top: 0.5rem;
			display: flex;
			justify-content: space-between;
		}
		@include tbl {margin-top: 1rem;}
		@include sp {margin-top: 3rem;}
	}
	.m-tab-contents & {
		border-bottom: 1px solid $_COLOR_BLUEGRAY;
		padding-bottom: 1.5rem
	}
}
.l-news-item {
	.top-news & {
		@include pc {width: 30.8%;}
		+.l-news-item {
			@include tblsp {margin-top: 2.5rem;}
		}
	}
	.m-tab-contents & {
		border-top: 1px solid $_COLOR_BLUEGRAY;
		padding: 1.5rem 0;
		&:nth-child(n+11) {display: none;}
	}
	.m-tab-contents .is-showAll &:nth-child(n+11) {display: block;}
	&__data {
		@include fs(15);
		line-height: $_LINE_HEIGHT_XS;
		color: #6b6b6b;
		margin-bottom: 1rem;
		@include sp {
			@include vw(14);
		}
		>span {
			display: inline-block;
			color: #fff;
			@include fs(13);
			line-height: $_LINE_HEIGHT_S;
			text-align: center;
			background-color: #333;
			width: 8.5em;
			margin-left: 1.5rem;
			padding: 2px 0 1px;
			@include sp {
				@include vw(10);
				width: 9em;
				margin-left: 1rem;
				white-space: nowrap;
			}
		}
	}
	&__txt {
		@include fs(16);
		line-height: $_LINE_HEIGHT_L;
		@include sp {
			@include vw(14);
		}
		>a {
			display: inline-block;
			color: #087dad;
			font-weight: bold;
			margin-top: 0.25rem;
			position: relative;
			@include pc {
				transition: 0.25s;
				span {position: relative;}
				&:hover span {
					&::after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						bottom: -1px;
						right: 0;
						border-bottom: 1px solid #087dad;
					}
				}
			}
			&::after {
				content: "";
				display: inline-block;
				width: 0.5em;
				height: 0.5em;
				border-top: 1px solid #087dad;
				border-right: 1px solid #087dad;
				transform: rotate(45deg);
				margin-left: 0.75em;
				vertical-align: 0.1em;
			}
			&[href*=".pdf"] {
				&::before {
					content: "PDF";
					display: inline-block;
					@include fs(12);
					line-height: $_LINE_HEIGHT_XS;
					font-weight: bold;
					text-align: center;
					white-space: nowrap;
					padding: 0.2em 0.75em;
					border: 1px solid #087dad;
					margin-right: 0.65rem;
					vertical-align: middle;
				}
			}
		}
		p a {
			text-decoration: underline;
			@include pc {
				&:hover {text-decoration: none;}
			}
		}
	}
}



//-------------------- l-pagetop

.l-pagetop {
	text-align: center;
	margin-top: 5rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	@include sp {
		margin-top: 3rem;
	}
	a {
		display: inline-block;
		padding: 1.5rem;
		color: $_COLOR_PAGETOP;
		&::before {
			content: "";
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			margin-left: auto;
			margin-right: auto;
			border-top: 2px solid $_COLOR_PAGETOP;
			border-left: 2px solid $_COLOR_PAGETOP;
			transform: translateY(20%) rotate(45deg);
			transition: 0.25s;
		}
		&:hover {
			@include pc {
				&::before {
					transform: translateY(0) rotate(45deg);
				}
			}
		}
		span {
			display: block;
			line-height: $_LINE_HEIGHT_MIN;
			margin-top: 1.0rem;
			@include fs($_FONT_SIZE_PAGETOP);
			@include sp {
				@include vw($_FONT_SIZE_PAGETOP_SP);
			}
		}
	}
}


//-------------------- l-contact
.l-contact {
	color: #fff;
	text-align: center;
	background-color: $_COLOR_MAIN;
	padding: 4.5rem 0;
}
.l-contact-txt {
	@include fs(28);
	line-height: $_LINE_HEIGHT;
	@include tbl {
		@include fs(22);
	}
	@include sp {
		@include vw(16);
	}
}

//-------------------- l-footer
.l-footer {
	line-height: $_LINE_HEIGHT;
	background-color: $_BG_COLOR_FOOTER;
	padding: 4rem 0 2.5rem;
	@include sp {padding: 3rem 0 1.25rem;}
	&__inner {
		@include inner;
		max-width: 1000px;
		@include sp {
			padding-left: 6.5vw;
			padding-right: 6.5vw;
		}
	}
}

// IE11 over write
@media all and (-ms-high-contrast: none) {
}
.l-footer-main {
	@include pctbl {
		display: flex;
		justify-content: space-between;
	}
	a {
		color: $_COLOR_FOOTER;
		@include pc {
			&:hover {text-decoration: underline;}
		}
		@include sp {
			display: block;
			position: relative;
			padding: 0.25em 0;
		}
		// &::after {
		// 	content: "";
		// 	display: inline-block;
		// 	width: 0.5em;
		// 	height: 0.5em;
		// 	border-top: 1px solid $_COLOR_FOOTER;
		// 	border-right: 1px solid $_COLOR_FOOTER;
		// 	transform: rotate(45deg) translateY(-20%);
		// 	@include pctbl {margin-left: 0.4rem;}
		// 	@include sp {
		// 		position: absolute;
		// 		right: 0;
		// 		top: 50%;
		// 	}
		// }
	}
	&__item {
		&:not(:first-child) {
			@include sp {margin-top: 1rem;}
		}

		&Category {
			@include fs($_FONT_SIZE_FOOTER);
			margin-bottom: 0.75rem;
			color: $_COLOR_FOOTER;
			@include sp {
				@include vw($_FONT_SIZE_FOOTER_SP);
				margin-bottom: 0.25rem;
			}
		}
		&Child {
			@include fs($_FONT_SIZE_FOOTER_CHILD);
			@include pctbl {
				margin-top: 0.5rem;
				padding-left: 1.0rem;
			}
			@include sp {
				@include vw($_FONT_SIZE_FOOTER_CHILD_SP);
				.l-footer-main__itemCategory + & {margin-top: 0.35rem;}
				&:last-child {margin-bottom: 1.25rem;}
			}
			a {
				color: $_COLOR_FOOTER_CHILD;
				@include sp {
					padding-left: 1rem;
				}
				// &::after {
				// 	border-top-color: $_COLOR_FOOTER_CHILD;
				// 	border-right-color: $_COLOR_FOOTER_CHILD;
				// }
			}
		}
	}
}
// .l-footer-sub-wrap {
// 	position: relative;
// 	margin-top: 3rem;
// 	@include pctbl {
// 		margin-left: -2rem;
// 		padding: 0 8rem;
// 	}
// 	@include sp {
// 		padding-top: 3.25rem;
// 		border-top: 1px solid $_BORDER_COLOR_FOOTER;
// 	}
// }
// .l-footer-sub {
// 	display: flex;
// 	justify-content: center;
// 	@include fs(13);
// 	line-height: 1;
// 	white-space: nowrap;
// 	@include sp {
// 		flex-wrap: wrap;
// 		@include vw(12);
// 	}
// 	a {
// 		@include pc {
// 			&:hover {text-decoration: underline;}
// 		}
// 	}
// 	&__item {
// 		&:not(:last-child) {
// 			margin-right: 1rem;
// 			padding-right: 1rem;
// 			border-right: 1px solid $_COLOR_FOOTER;
// 		}
// 		&:last-child {
// 			@include sp {margin-top: 1rem;}
// 		}
// 	}
// }
// .l-footer-privacy {
// 	width: 7rem;
// 	@include pctbl {
// 		position: absolute;
// 		right: 0;
// 		top: -2rem;
// 	}
// 	@include sp {
// 		width: 25vw;
// 		margin: 1.5rem auto 0;
// 	}
// }
.l-footer-copy {
	color: $_COLOR_FOOTER;
	@include fs($_FONT_SIZE_FOOTER_COPY);
	line-height: $_LINE_HEIGHT_S;
	text-align: center;
	margin-top: 2rem;
	@include sp {
		@include vw($_FONT_SIZE_FOOTER_COPY_SP);
		margin-top: 1.5rem;
	}
}
