@charset "utf-8";

//-------------------- form

.form-nav {
	display: flex;
	justify-content: space-between;
	text-align: center;
	&__item {
		@include fs(18);
		width: 29%;
		border: 1px solid $_COLOR_BLUEGRAY;
		padding: 0.75rem 0.5rem;
		position: relative;
		@include tbl {
			@include fs(16);
		}
		@include sp {
			@include vw(13);
			white-space: nowrap;
			padding: 0.75rem 0.5rem;
		}
		&.is-act {
			border-width: 2px;
			background-color: #edf9fc;
		}
		&+& {
			&::before {
				content: "";
				display: block;
				width: 1rem;
				height: 1rem;
				border-top: 2px solid #333;
				border-right: 2px solid #333;
				position: absolute;
				left: -15%;
				top: 50%;
				margin: -0.5rem 0 0 -0.25rem;
				transform: rotate(45deg);
				@include sp {
					width: 0.65rem;
					height: 0.65rem;
					margin-left: -0.45rem;
				}
			}
		}
	}
}

.form-col {
	margin: 3.25rem 0;
	padding-bottom: 1rem;
	background-color: $_COLOR_GRAY;
	body.is-confirm & {padding-bottom: 0;}
	@include pctbl {
		display: flex;
		flex-wrap: wrap;
	}
	@include sp {
		margin: 3rem -4vw;
	}
	&__label, &__body {
		line-height: $_LINE_HEIGHT_S;
		@include pctbl {
			border-top: 1px solid #fff;
		}
		@include sp {
			@include vw(14);
		}
	}
	&__label {
		@include fs(16);
		font-weight: bold;
		position: relative;
		@include pc {
			width: 15rem;
			padding: 1.65rem 4.25rem 1rem 2.5rem;
		}
		@include tbl {
			width: 13rem;
			padding: 1.65rem 4rem 1rem 1.5rem;
		}
		@include sp {
			padding: 1.1rem 4vw 0;
			border-top: 1px solid #fff;
		}
		body.is-confirm & {padding-top: 1.15rem;}
	}
	&__body {
		@include pc {
			width: calc(100% - 15rem);
			padding: 1rem 4rem 1rem 2rem;
		}
		@include tbl {
			width: calc(100% - 13rem);
			padding: 1rem 1.5rem;
		}
		@include sp {
			padding: 0.75rem 4vw 1.25rem;
		}
	}
}
.form-input {
	&+& {margin-top: 0.75rem;}
	input:not([type="radio"]):not([type="checkbox"]), select, textarea {
		color: inherit;
		font: inherit;
		width: 100%;
		padding: 0.65rem 1.25rem;
		border: 1px solid $_COLOR_GRAY;
		overflow: hidden;
		vertical-align: middle;
		@include sp {
			@include vw(14);
			padding: 0.65rem;
		}
		&.is-S {
			width: 10rem;
			@include sp {width: 8rem;}
		}
	}
	input:not([type="radio"]):not([type="checkbox"]) {
		@include pc {width: 88%;}
	}
	select {
		-webkit-appearance: none;
		appearance: none;
	}
	textarea {
		line-height: $_LINE_HEIGHT_S;
		height: 14rem;
		padding-top: 1rem;
	}
	&.is-error {
		input:not([type="radio"]):not([type="checkbox"]), textarea {
			border-color: $_COLOR_EM;
		}
	}
	/*&.is-select {
		display: inline-flex;
		width: 70%;
		position: relative;
		max-width: 100%;
		@include sp {width: 100%;}
		&+& {margin-top: 0.5rem;}
		&::before {
			content: "";
			width: 2.5em;
			height: 2.5em;
			position: absolute;
			right: 0.75em;
			top: 0.4em;
			background-color: #fff;
			pointer-events: none;
			z-index: 2;
		}
		&::after {
			content: "";
			width: 0.4em;
			height: 0.4em;
			position: absolute;
			right: 1em;
			top: 1.25em;
			border-right: 1px solid $_COLOR_GRAY;
			border-bottom: 1px solid $_COLOR_GRAY;
			transform: rotate(45deg);
			pointer-events: none;
			z-index: 3;
			@include sp {top: 1.5em;}
		}
	}*/
	&.is-radio {
		>label {
			display: inline-block;
			@include fs(16);
			padding: 0.55rem 0;
			cursor: pointer;
			@include tblsp {display: block;}
			@include sp {
				@include vw(14);
			}
			&:not(:last-child) {margin-right: 1.5rem;}
			&::before {
				content: "";
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				background: url(../images/common/radio.svg) no-repeat center;
				background-size: 96% auto;
				vertical-align: -0.35em;
				margin-right: 0.25rem;
			}
		}
		input[type="radio"] {
			display: none;
			&:checked + label::before {
				background-image: url(../images/common/radio_checked.svg);
			}
		}
	}
	a {text-decoration: underline;}

}
.form-select {
}
.form-input-txt {
	line-height: $_LINE_HEIGHT;
	font-weight: bold;
	margin: 0.4rem 0 0.75rem;
	a {text-decoration: underline;}
}
.form-alert {
	@include fs(13);
	color: $_COLOR_EM;
	font-weight: bold;
	line-height: $_LINE_HEIGHT_S;
	margin-top: 0.75em;
	position: relative;
	&+& {margin-top: 0.4em;}
	@include sp {
		@include vw(12);
	}
	+ .is-textarea {margin-top: 1rem;}
}
.form-required {
	display: inline-block;
	color: #087dad;
	@include fs(12);
	font-weight: bold;
	line-height: 1;
	border: 1px solid #087dad;
	padding: 0.5em 0.75em;
	@include pctbl {
		position: absolute;
		top: 1.5rem;
		right: 0;
	}
	@include sp {
		margin-left: 0.75rem;
		vertical-align: 0.15em;
	}
}

.form-btn-col {
	position: relative;
	@include pctbl {
		display: flex;
		justify-content: center;
	}
	&__item {
		@include pctbl {
			width: 45%;
			max-width: 450px;
		}
		&+& {
			@include pctbl {margin-left: 2rem;}
			@include sp {margin-top: 1.25rem;}
		}
	}
}
.form-privacy {
	@include fs(12);
	line-height: $_LINE_HEIGHT_S;
	margin-bottom: 2rem;
	text-align: center;
	a {text-decoration: underline;}
	@include sp {
		@include vw(12);
	}
}
#privacy {display: none;}
.privacy-check {
	display: inline-block;
	margin-right: 1em;
	position: relative;
	cursor: pointer;
	&::before, &::after {
		content: "";
		display: inline-block;
		width: 1em;
		height: 1em;
		@include sp {
			width: 1.25em;
			height: 1.25em;
		}
	}
	&::before {
		margin-right: 0.5rem;
		background-color: #fff;
		border: 1px solid #cdd6dd;
		vertical-align: -0.15em;
		z-index: 1;
		@include sp {vertical-align: -0.25em;}
	}
	&::after {
		position: absolute;
		left: 0.1em;
		top: 0.25em;
		background: url(../images/common/icon_check.svg) no-repeat center;
		background-size: 0.95em auto;
		opacity: 0;
		@include sp {background-size: contain;}
	}
	input:checked + &::after {
		opacity: 1;
	}
}

// /contact/thanks.php

.contact-thanks {
	font-weight: bold;
	text-align: center;
	line-height: $_LINE_HEIGHT;
	margin: 5rem 0;
	@include sp {margin: 3.25rem 0;}
	&__lead {
		@include fs(30);
		margin-bottom: 1.5rem;
		@include sp {
			@include vw(20);
		}
	}
	&__body {
		@include sp {text-align: left;}
		p+p {margin-top: 1rem;}
	}
}