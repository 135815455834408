@charset "utf-8";

//-------------------- busuness-results 事業紹介 / 実績紹介



//-------------------- busuness-results 事業紹介 / 実績紹介
.busuness-results-box {
	&__body {
		padding: 1.0em 0 2.0em;
		@include sp {
			padding-bottom: 0;
		}
	}
	&__bodyTitle {
		line-height: 1.6;
		color: $_COLOR_HEADLINE;
		@include fs(24);
		@include sp {
			@include vw(20);
		}
		margin-bottom: 0.5em;
	}
	&__bodyTxt {
		line-height: 1.6;
		@include fs(16);
		@include sp {
			@include vw(16);
		}
	}
}