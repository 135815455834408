@charset "utf-8";

// スマホデザイン幅 px不要
$_DESIGN_WIDTH: 375;

// PC幅
$_INNER_WIDTH: 1600px;

//.l-inner padding設定
$_PADDING_PC: 50px;
$_PADDING_TB: 30px;
$_PADDING_SP:  4vw;

// PC最大幅
$_MAX_WIDTH: $_PADDING_PC * 2 + $_INNER_WIDTH;

// ブレイクポイント
$_BREAK_POINT:    750px;
$_BREAK_POINT_TB: 1024px;

// フォントファミリー
$_FONT_FAMILY:   'Noto Sans JP',"Hiragino Kaku Gothic ProN", "游ゴシック体", "Yu Gothic", YuGothic, Meiryo, sans-serif;
// $_FONT_FAMILY_EN: 'Oswald', Helvetica, Arial, sans-serif;
$_FONT_FAMILY_EN: $_FONT_FAMILY;

// フォントサイズ pc-px & sp-vw
$_FONT_SIZE:      16;
$_FONT_SIZE_PC_S: 16;
$_FONT_SIZE_SP:   16;

// フォントウェイト
$_FONT_WEIGHT_BOLD:   700;
$_FONT_WEIGHT_MEDIUM: 500;
$_FONT_WEIGHT:        400;

// 行高さ
$_LINE_HEIGHT_L:   2;
$_LINE_HEIGHT:     1.8;
$_LINE_HEIGHT_S:   1.5;
$_LINE_HEIGHT_XS:  1.25;

$_LINE_HEIGHT_MIN: 1.0;



// フォントカラー
$_COLOR_TXT: #333;
$_COLOR_PLACEHOLDER: #bebebe;

$_COLOR_HEADLINE: #0f0e9f;


// リンク設定
$_COLOR_LINK: #333;
$_LINK_DECO:  none;

// 背景色
$_BG_COLOR_CONVERSION: #008DCB;


$_BG_COLOR_BUTTON: #162a82;
$_COLOR_BUTTON: #fff;
$_BG_COLOR_BUTTON_HOVER: #6f8abf;
$_COLOR_BUTTON_HOVER: #fff;


$_COLOR_GRAY: #f4f2ef; //ベースグレー
$_COLOR_MAIN: #008DCB; //ブルー
$_COLOR_EM: #f90303; //赤
$_COLOR_BLUEGRAY: #B7D0DB; //ブルーグレー

// 選択背景色
$_COLOR_SELECTION: #0f0e9f;


// PAGETOP
// ------------------------------------------------
$_COLOR_PAGETOP: #6F7579;
$_FONT_SIZE_PAGETOP: 20;
$_FONT_SIZE_PAGETOP_SP: 14;


// ヘッダー
// ------------------------------------------------
// 基本色（テキスト、背景色）
$_COLOR_HEADER: #162A82;
// 反転色（テキスト、矢印、背景色）
$_COLOR_HEADER_INVERSION: #fff;
// 矢印色
$_COLOR_HEADER_ARROW: #6F7579;

// ボーダー色（スマホ版）
$_BORDER_COLOR_HEADER: #eee;
// 子要素の基本色（テキスト／スマホ版）
$_COLOR_HEADER_CHILD: #333;

// フォントサイズ
// 親要素
$_FONT_SIZE_HEADER:    14;
$_FONT_SIZE_HEADER_TB: 16;
$_FONT_SIZE_HEADER_SP: 16;
// 子要素
$_FONT_SIZE_HEADER_CHILD:    14;
$_FONT_SIZE_HEADER_CHILD_TB: 16;
$_FONT_SIZE_HEADER_CHILD_SP: 16;



// フッター
// ------------------------------------------------
// 基本色（テキスト、矢印）
$_COLOR_FOOTER: #cdd6dd;
// 背景色
$_BG_COLOR_FOOTER: #090936;
// ボーダー色（スマホ版）
$_BORDER_COLOR_FOOTER: #090936;
// 子要素の基本色（テキスト）
$_COLOR_FOOTER_CHILD: #fff;

// フォントサイズ
// 親要素
$_FONT_SIZE_FOOTER:    16;
$_FONT_SIZE_FOOTER_SP: 16;
// 子要素
$_FONT_SIZE_FOOTER_CHILD:    16;
$_FONT_SIZE_FOOTER_CHILD_SP: 16;
// コピーライト
$_FONT_SIZE_FOOTER_COPY:    12;
$_FONT_SIZE_FOOTER_COPY_SP: 14;
