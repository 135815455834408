@charset "utf-8";

//-------------------- m-title
//-------------------- m-page-lead
//-------------------- m-txt-lead

//-------------------- m-btn

//-------------------- m-def
//-------------------- m-table
//-------------------- m-tab
//-------------------- m-txt-box
//-------------------- m-image-box
//-------------------- m-list / m-list-link
//-------------------- m-history
//-------------------- m-blank

//-------------------- m-anchor
//-------------------- m-boxlink
//-------------------- m-map

//-------------------- slick
//-------------------- m-general
//-------------------- WYSIWYG



//-------------------- m-title

.m-title {
	font-weight: $_FONT_WEIGHT_BOLD;
	position: relative;
	z-index: 2;
	&.is-vertical {
		text-align: center;
		margin-bottom: 4rem;
		@include sp {margin-bottom: 3.25rem;}
		&:not(:first-child) {
			margin-top: 7.5rem;
			@include tbl {margin-top: 6.25rem;}
			@include sp {margin-top: 5rem;}
		}
	}
	&.is-level {
		margin-bottom: 3.5rem;
		@include sp {
			margin-bottom: 2.0rem;
		}
		// &:not(:first-child) {
		// 	margin-top: 5rem;
		// 	@include tbl {margin-top: 4.25rem;}
		// 	@include sp {margin-top: 3.5rem;}
		// }
	}

	&.is-center {
		@include pctbl {justify-content: center;}
	}
	.l-page-title & {margin: 0;}

	&__en {
		font-family: $_FONT_FAMILY_EN;
		@include fs(48);
		@include tbl {
			@include fs(50);
		}
		@include sp {
			@include vw(34);
		}
		color: $_COLOR_HEADLINE;
		.is-color-white & {
			color: #fff;
		}

		.is-vertical & {
			display: block;
			// color: $_COLOR_HEADLINE;
			// .l-page-title & {
			// 	color: #fff;
			// 	@include fs(70);
			// 	@include tbl {
			// 		@include fs(60);
			// 	}
			// 	@include sp {
			// 		@include vw(40);
			// 	}
			// }
			// .l-page-title.is-noBg & {color: #333;}
		}
		.is-level & {
			display: inline-block;
			vertical-align: bottom;
			line-height: $_LINE_HEIGHT_MIN;
		}
		// .l-contact & {
		// 	color: #fff;
		// }
	}
	&__jp {
		@include fs(16);
		letter-spacing: 0.05em;
		@include sp {
			@include vw(16);
		}
		.is-color-white & {
			color: #fff;
		}
		.is-vertical & {
			display: block;
			margin-top: 1.25rem;
			@include sp {
				margin-top: 1rem;
			}

			// 下層ページ（英語タイトルなし）
			&:only-child {
				@include fs(35);
				line-height: $_LINE_HEIGHT_XS;
				margin-top: 0;
				@include sp {
					@include vw(30);
				}
			}
		}
		.is-level & {
			display: inline-block;
			vertical-align: bottom;
			line-height: $_LINE_HEIGHT_S;
			margin-left: 1.0rem;
		}
		// .l-contact & {
		// 	color: #fff;
		// 	border-left-color: #fff;
		// }
	}
}

.m-title-section {
	text-align: center;
	color: $_COLOR_HEADLINE;
	font-weight: $_FONT_WEIGHT_BOLD;
	@include fs(32);
	@include sp {
		@include vw(22);
	}
	margin-bottom: 1.28em;
	@include sp {
		margin-bottom: 1.36em;
	}
}

.m-title-deco {
	@include fs(24);
	@include sp {
		@include vw(18);
	}
	margin-bottom: 0.96em;
	@include sp {
		margin-bottom: 0.83em;
	}
	font-weight: $_FONT_WEIGHT_MEDIUM;
	line-height: $_LINE_HEIGHT;
	padding-left: 0.8em;
	position: relative;
	&::before {
		content: "";
		display: block;
		width: 0.3em;
		height: 1.3em;
		background-color: $_COLOR_HEADLINE;
		position: absolute;
		left: 0;
		top: 0.25em;
	}
}

.m-title-solid {
	@include fs(38);
	font-weight: normal;
	line-height: $_LINE_HEIGHT_S;
	margin-bottom: 1.5rem;
	&:not(:first-child) {
		margin-top: 4rem;
		@include sp {margin-top: 2.75rem;}
	}
	@include tbl {
		@include fs(34);
	}
	@include sp {
		@include vw(22);
		font-weight: bold;
		text-align: center;
	}
}


//-------------------- m-page-lead
.m-page-lead {
	@include fs(32);
	line-height: 1.6;
	font-weight: normal;
	text-align: center;
	color: $_COLOR_HEADLINE;
	font-weight: $_FONT_WEIGHT_BOLD;
	margin-bottom: 1.2rem;
	// @include tbl {
	// 	@include fs(40);
	// }
	@include sp {
		@include vw(22);
		line-height: $_LINE_HEIGHT_S;
	}
}
.m-page-lead-sub {
	line-height: $_LINE_HEIGHT_S;
	text-align: center;
	@include sp {
		text-align: left;
		line-height: $_LINE_HEIGHT;
	}
}

//-------------------- m-txt-lead

.m-txt-lead {
	@include fs(16);
	font-weight: bold;
	text-align: center;
	line-height: $_LINE_HEIGHT_L;
	@include sp {
		@include vw(16);
		line-height: $_LINE_HEIGHT;
		text-align: left;
	}
	.l-cross-image-wrap & {
		font-weight: bold;
	}
}

//-------------------- m-txt-link

.m-txt-link a, a.m-txt-link {
	// color: #1a69d5;
	text-decoration: underline;
	@include pc {
		&:hover {text-decoration: none;}
	}
}


//-------------------- m-btn
.m-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	&-txt {
		display: block;
		width: 100%;
		padding: 0 1.0em;
		text-align: center;
		position: relative;
	}
	display: table;
	border-collapse: separate;
	width: 100%;
	margin: 0 auto;
	position: relative;
	@include pctbl {
		max-width: 450px;
	}
	@include sp {
		max-width: 86vw;
	}
	a, button, input, .m-btn-txt__nolink {
		display: table-cell;
		color: #333;
		width: 100%;
		height: 4.7rem;
		font: inherit;
		@include fs(18);
		font-weight: bold;
		line-height: $_LINE_HEIGHT_S;
		text-decoration: none;
		text-align: center;
		vertical-align: middle;
		padding: 0.25rem 0.5em;
		background-color: $_BG_COLOR_BUTTON;
		color: $_COLOR_BUTTON;
		&:not(:disabled):not(.m-btn-txt__nolink) {
			cursor: pointer;
		}
		@include pc {
			transition: 0.25s;
			&::before, &::after {
				transition: 0.25s;
			}
			&:hover {
				color: $_COLOR_BUTTON_HOVER;
				background-color: $_BG_COLOR_BUTTON_HOVER;
			}
		}
		@include sp {
			@include vw(16);
			height: 3.5rem;
		}
	}

	&.is-down {
		a, button, input, .m-btn-txt__nolink {
			&::after {
				content: "";
				display: inline-block;
				width: 0.55em;
				height: 0.55em;
				border-bottom: 1px solid $_COLOR_BUTTON;
				border-right: 1px solid $_COLOR_BUTTON;
				transform: rotate(45deg);
				position: absolute;
				right: 1.5rem;
				top: 50%;
				margin-top: -0.4em;
			}
			@include pc {
				&:hover::after {
					border-color: $_COLOR_BUTTON_HOVER;
				}
			}
		}
	}
	&.is-blank {
		a, button, input, .m-btn-txt__nolink {
			color: #333;
			background-color: $_COLOR_GRAY;
			&::after {
				content: "";
				display: inline-block;
				width: 1.25em;
				height: 1em;
				background: url(../images/common/icon_blankBk.svg) no-repeat center;
				background-size: contain;
				position: absolute;
				right: 1.25rem;
				top: 50%;
				margin-top: -0.45em;
				@include tbl {right: 1rem;}
			}
			@include pc {
				&:hover {
					color: #fff;
					background-color: #333;
					&::after {background-image: url(../images/common/icon_blankW.svg);}
				}
			}
		}
	}
	&.is-arrow {
		a, button, input, .m-btn-txt__nolink {
			&::after {
				content: "";
				display: inline-block;
				width: 0.65em;
				height: 0.65em;
				border-top: 1px solid $_COLOR_BUTTON;
				border-right: 1px solid $_COLOR_BUTTON;
				transform: rotate(45deg);
				position: absolute;
				right: 1.5rem;
				top: 50%;
				margin-top: -0.32em;
			}
			@include pc {
				&:hover {
					&::after {
						border-color: $_COLOR_BUTTON_HOVER;
						right: 1.3rem;
					}
				}
			}
		}
	}
	&.is-act {
		a, button, input, .m-btn-txt__nolink {
			color: $_COLOR_BUTTON_HOVER;
			background-color: $_BG_COLOR_BUTTON_HOVER;
			&::after {
				border-color: $_COLOR_BUTTON_HOVER;
			}
		}
	}
/**
	&.is-entry {
		color: #fff;
		font-weight: $_FONT_WEIGHT_BOLD;
		background-color: $_BG_COLOR_CONVERSION;
		padding: 1.0em;
		width: 150px;
	}
	&.is-submit, &.is-form {
		a, button, input {
			@include pc {
				&:hover {
					color: #fff;
					background-color: #087dad;
				}
			}
		}
	}
	&.is-submit {
		a, button, input {
			color: #fff;
			background-color: #333;
		}
	}
*/

	&.is-S {
		@include pctbl {max-width: 250px;}
		@include sp {max-width: 240/375*100vw;}
		a, button, input {
			@include fs(14);
			height: 3.05rem;
			@include tbl {
				@include fs(15);
			}
			@include sp {
				@include vw(15);
				height: 3.4rem;
			}
		}
	}
	&.is-L {
		@include pctbl {max-width: 600px;}
		@include sp {max-width: 100%;}
		a, button, input {
			@include fs(28);
			text-align: left;
			height: 8rem;
			padding-left: 2em;
			@include tbl {
				@include fs(24);
				height: 6.5rem;
			}
			@include sp {
				@include vw(18);
				height: 5rem;
			}
		}
	}
	&.is-mt0 {
		margin-top: 0;
	}
}




//-------------------- m-table
.m-table {
	@include fs(16);
	line-height: $_LINE_HEIGHT;
	width: 100%;
	border-top: 1px solid $_COLOR_BLUEGRAY;
	&:not(:first-child) {margin-top: 1rem;}
	&:not(:last-child) {margin-bottom: 1.5rem;}
	@include sp {
		@include vw(14);
	}
	[class*="m-list"] {
		&:not(:first-child) {margin-top: 0.5rem;}
		+ [class*="m-list"] {margin-top: 0.75rem;}
	}
	a {text-decoration: underline;}
	th, td {
		padding: 0.85rem 2rem;
		border-bottom: 1px solid $_COLOR_BLUEGRAY;
		vertical-align: top;
		font-weight: normal;
		@include sp {padding: 0.65rem 0.75rem;}
	}
	th {
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		background-color: #edf9fc;
		@include sp {
			padding: 0.65rem 0.5rem;
		}
	}
	thead th {
		color: #fff;
		background-color: $_COLOR_MAIN;
		+th {border-left: 1px solid #fff;}
	}
	tbody th {
		width: 13rem;
		@include tbl {width: 12rem;}
		@include sp {width: 6.5rem;}
	}
	td {
		background-color: #fff;
		text-align: left;
		> a {
			color: $_COLOR_EM;
			font-weight: bold;
		}
	}
}
//-------------------- m-history
.m-table-simple {
	border-bottom: 1px solid #D5D5D5;
	&__item {
		overflow: hidden;
		padding: 1.0em 0;
		border-top: 1px solid #D5D5D5;
		dt {
			color: $_COLOR_HEADLINE;
			font-weight: $_FONT_WEIGHT_BOLD;
			@include pctbl {
				float: left;
			}
		}
		dd {
			@include pctbl {
				padding-left: 8.0em;

				.is-recruit & {
					padding-left: 12.0em;
				}
			}
		}
	}

	&.has-mark {
		dt {
			&::before {
				content: "";
				display: inline-block;
				width: 0.7em;
				height: 0.7em;
				background-color: $_COLOR_HEADLINE;
				margin-right: 0.4em;
			}
		}
	}

}



//-------------------- m-tab
.m-tab {
	display: flex;
	justify-content: space-between;
	text-align: center;
	border-bottom: 2px solid #333;
	position: relative;
	@include tbl {}
	@include sp {}
	&::after {
		content: "";
		display: inline-block;
		width: 2px;
		height: 1rem;
		background-color: #333;
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&__item {
		@include fs(18);
		line-height: $_LINE_HEIGHT_XS;
		font-weight: bold;
		width: 25%;
		padding: 1em;
		border-radius: 0.8rem 0.8rem 0 0;
		transition: 0.25s;
		position: relative;
		@include pc {
			transition: 0.25s;
			cursor: pointer;
			&:hover {
				color: #fff;
				background-color: #333;
			}
		}
		@include tbl {
			@include fs(16);
			padding: 1em 0.75em;
		}
		@include sp {
			display: flex;
			justify-content: center;
			align-items: center;
			@include vw(12);
			padding: 0.25rem 0.25rem;
			height: 3.25em;
			border-width: 2px;
			border-radius: 0.4rem 0.4rem 0 0;
		}
		&::before {
			content: "";
			display: inline-block;
			width: 2px;
			height: 1rem;
			background-color: #333;
			position: absolute;
			left: 0;
			bottom: 0;
		}
		&.is-act {
			color: #fff;
			background-color: #333;
			cursor: default;
			+ .m-tab__item {
				&::before {
					content: none;
				}
			}
		}
	}
}
.m-tab-contents {
	&__item {
		display: none;
		margin: 0 auto;
		padding: 3.5rem 0;
	}
}
// IE11 over write
@media all and (-ms-high-contrast: none) {
	.m-tab {margin-bottom: -1px;}
}

//-------------------- m-txt-box
.m-txt-box {
	line-height: $_LINE_HEIGHT;
	>p:not(:first-child):not([class]) {
		margin-top: 1.5em;
		@include sp {margin-top: 1.25rem;}
	}
}

//-------------------- m-image-box
.m-image-box {
	text-align: center;

	.m-txt-box + & {
		margin-top: 3.5rem;
		@include sp {
			margin-top: 2.5rem;
		}
	}
}

//-------------------- m-list / m-list-link

.m-list-std {
	line-height: $_LINE_HEIGHT_S;
	>li {
		padding-left: 1.25em;
		text-indent: -1.25em;
		+li {margin-top: 0.2rem;}
	}
	&.is-blank >li+li {margin-top: 0.75rem;}
}
.m-list-num {
	line-height: $_LINE_HEIGHT_S;
	list-style-type: decimal;
	padding-left: 1.25em;
	>li+li {margin-top: 0.35em;}
	&.is-blank >li+li {margin-top: 1rem;}
	&.is-blankL {
		>li p:first-child:not(:last-child) {margin-bottom: 0.5rem;}
		>li+li {margin-top: 2rem;}
	}
}


//-------------------- m-blank
hr.m-blank {
	display: block;
	height: 0;
	margin: 3.8rem auto;
	border: none;
	border-top: 1px solid $_COLOR_GRAY;
	body.is-know & {
		border-top-color: rgba(255,255,255,0.3);
		margin-top: 8rem;
		@include tbl {margin-top: 5rem;}
		@include sp {margin-top: 3.5rem;}
	}
	@include sp {margin: 2.5rem auto;}
	&.is-S {
		margin: 2.25rem auto;
		@include sp {margin: 2rem auto;}
	}
}


//-------------------- m-anchor
.m-anchor {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto;
	@include sp {flex-wrap: wrap;}
	&__item {
		@include pctbl {width: 100%;}
		@include sp {
			width: 45%;
			&:nth-child(n+3) {margin-top: 0.5rem;}
		}
	}
	a {
		display: block;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.1em;
		width: 8rem;
		margin: 0 auto;
		padding: 1rem 0 2rem;
		position: relative;
		@include sp {
			text-align: left;
			width: 100%;
			padding: 0.75rem 2rem 0.75rem 0;
			border-bottom: 1px solid #eee;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 0.5em;
			height: 0.5em;
			border-right: 2px solid $_COLOR_MAIN;
			border-bottom: 2px solid $_COLOR_MAIN;
			transform: rotate(45deg);
			position: absolute;
			@include pctbl {
				left: 50%;
				bottom: 0.4rem;
				margin-left: -0.25rem;
				transition: 0.25s;
			}
			@include sp {
				width: 0.4em;
				height: 0.4em;
				right: 0.5rem;
				top: 0.85rem;
			}
		}
		&:hover::after {
			@include pc {bottom: 0;}
		}
	}
}

//-------------------- m-boxlink
.m-boxlink {
	display: block;
	background-color: #fff;
	position: relative;
	&__picture {
		@include pc {
			transition: opacity 0.25s;
		}
	}
	&__body {
		padding: 1.5rem 1.0rem 2.0rem;
	}
	&__bodyTitle {
		@include fs(20);
		@include sp {
			@include vw(20);
		}
		font-weight: $_FONT_WEIGHT_MEDIUM;
	}
	&__bodyTxt {
		.m-boxlink__bodyTitle + & {
			margin-top: 1.0rem;
		}
		line-height: $_LINE_HEIGHT;
	}
	&::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border: 0.5rem solid $_COLOR_HEADLINE;
		border-top: 0.5rem solid transparent;
		border-left: 0.5rem solid transparent;
		position: absolute;
		right: 5px;
		bottom: 5px;
		@include pc {
			transition: opacity 0.25s;
		}
	}
	@include pc {
		&:hover {
			.m-boxlink__picture {
				opacity: 0.6;
			}
			&::after {
				opacity: 0.6;
			}
		}
	}

}

//-------------------- m-map
.m-map {
	position: relative;
	height: 450px;
	@include tbl {height: 380px;}
	@include sp {height: 90vw;}
	iframe {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.m-map-link {
	margin: 1rem 0 1.25rem;
	a {
		color: #087dad;
		@include fs(14);
		font-weight: bold;
		@include pc {
			&:hover {text-decoration: underline;}
		}
		@include sp {
			@include vw(14);
		}
		&::after {
			content: "";
			display: inline-block;
			width: 0.5em;
			height: 0.5em;
			border-top: 1px solid #087dad;
			border-right: 1px solid #087dad;
			transform: rotate(45deg);
			margin: -0.3em 0 0 0.75em;
		}
	}
}

//-------------------- slick

// dot
.slick-dots {
	display: flex;
	justify-content: center;
	position: absolute;
	left: 50%;
	bottom: 0;
	z-index: 3;
	transform: translate(-50%, -250%);
	&__dot {
		width: 11px;
		height: 11px;
		border: 1px solid #fff;
		border-radius: 50%;
		margin: 0 0.75rem;
		cursor: pointer;
		@include tblsp {margin: 0 0.5rem;}
		@include sp {
			width: 10px;
			height: 10px;
		}
	}
}
.slick-active .slick-dots__dot {background-color: #fff;}
.slick-slide img {
	margin: 0 auto;
	@include pcL {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
//arrow
.slick-arrow {
	position: absolute;
	top: 50%;
	margin-top: -1.8rem;
	width: 2.8rem;
	height: 2.8rem;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	cursor: pointer;
	z-index: 5;
}
.slick-prev {
	left: 2.5rem;
	transform: rotate(-135deg);
	@include pcL {
		left: 50%;
		margin-left: -650px;
	}
	@include tbl {left: 1.25rem;}
}
.slick-next {
	right: 2.5rem;
	transform: rotate(45deg);
	@include pcL {
		right: 50%;
		margin-right: -650px;
	}
	@include tbl {right: 1.25rem;}
}

//-------------------- m-general
.m-general-title {
	@include fs(16);
	font-weight: bold;
	line-height: $_LINE_HEIGHT;
	margin: 2rem 0 0.5rem;
	@include sp {
		@include vw(16);
	}
	+p:not([class]) {margin-top: 0!important;}
	[class*="m-list"] & {
		@include fs(14);
		@include sp {
			@include vw(14);
			margin-top: 1.75rem;
		}
	}
}


//-------------------- WYSIWYG
.cms-article {
	// [START] WYSIWYG用スタイル
	line-height: $_LINE_HEIGHT_L;
	&:not(:first-child) {margin-top: 1.75rem;}
	.l-num-list & {margin-top: 0;}
	&::after {
		content: "";
		display: table;
		clear: both;
	}
	p {
		margin-top: 2.0em;
		&:first-child {
			margin-top: 0;
		}
		@include sp {
			&:not(:first-child) {margin-top: 1.25em;}
		}
	}

	ul {
		margin-bottom: 2.0em;
		padding-left: 2.0em;
		list-style-type: disc;
		li {
			margin-top: 0.5em;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	ol {
		margin-bottom: 2.0em;
		padding-left: 2.0em;
		list-style-type: decimal;
		li {
			margin-top: 0.5em;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	hr {
		margin-top: 1.0em;
		margin-bottom: 1.0em;
	}

	img {
		width: auto;
		max-width: 100%;
		height: auto;

		&.aligncenter {
			display: block;
		}
	}

	.alignleft {
		max-width: 40%;
		float: left;
		margin-right: 5%;
		margin-bottom: 3%;
		@include sp {
			margin-right: 20px;
			margin-bottom: 10px;
		}
	}
	.alignright {
		max-width: 40%;
		float: right;
		margin-left: 5%;
		margin-bottom: 3%;
		@include sp {
			margin-left: 20px;
			margin-bottom: 10px;
		}
	}
	.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}

	.wp-caption-text {
		margin-top: 0.5em;
		text-align: left;
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}


	h2 {
		// .m-title.is-S 相当
		@include fs(30);
		margin-bottom: 1.25em;
		@include tbl {
			@include fs(26);
		}
		@include sp {
			@include vw(20);
		}
	}
	h3 {
		@include fs(20);
		font-weight: bold;
		// margin-bottom: 2.5rem;
		margin-bottom: 1.25em;
		@include sp {
			@include vw(18);
			margin-bottom: 1.5rem;
		}
	}
	h4 {
		margin-bottom: 1.25em;
		font-weight: bold;
	}

	// [END] WYSIWYG用スタイル

	//既存MTstyle上書き
	.mt-image-left, .mt-image-right {
		max-width: 40%!important;
		margin-bottom: 3%!important;
		@include sp {
			margin-bottom: 10px!important;
		}
	}
	.mt-image-left {
		float: left;
		margin-right: 5%!important;
		@include sp {
			margin-right: 20px!important;
		}
	}
	.mt-image-right {
		float: right;
		margin-left: 5%!important;
		@include sp {
			margin-left: 20px!important;
		}
	}
}
