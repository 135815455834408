@charset "utf-8";

html {
	font-family: $_FONT_FAMILY;
	color: $_COLOR_TXT;
	font-weight: $_FONT_WEIGHT;
	word-wrap: break-word;
	font-size: $_FONT_SIZE * 1px;
	@include pcS {font-size: $_FONT_SIZE_PC_S * 1px;}
	@include tbl {font-size: $_FONT_SIZE_PC_S * 1px;}
	@include sp {
		@include vw($_FONT_SIZE_SP);
	}
}
body {
	width: 100%;
	height: 100%;
	background-color: #fff;
	-webkit-text-size-adjust: 100%;
	&.is-fixed {
		position: fixed;
		overflow-x: hidden;
		overflow-y: scroll;
	}
}
// IE11 over wright
@media all and (-ms-high-contrast: none) {
	html, input, select, textarea {
		font-family: Meiryo, sans-serif!important;
		line-height: 1;
		font-weight: normal;
	}
}
a {
	text-decoration: $_LINK_DECO;
	color: $_COLOR_LINK;
	@include pc {
		&:hover {text-decoration: none;}
	}
}
img {
	vertical-align: bottom;
	max-width: 100%;
	max-height: 100%;
	@include sp {
		width: 100%;
	}
}
strong {font-weight: 700;}
em {font-style: italic;}
picture {display: block;}
input {
	padding: 0;
	border: none;
	background: none;
}
* {box-sizing: border-box;}

input[type=text],
input[type=tel],
input[type=password],
input[type=email],
input[type=search],
input[type=url],
input[type=datetime],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime-local],
input[type=number],
select,
textarea {
	max-width: 100%;
	padding: 0.5rem 0.75rem;
	font: inherit;
	background-color: #fff;
	border: none;
	vertical-align: bottom;
	&::-webkit-input-placeholder {
		color: $_COLOR_PLACEHOLDER;
	}
	&:-ms-input-placeholder {
		color: #c0c0c0!important;
	}
	&::-moz-placeholder {
		color: $_COLOR_PLACEHOLDER;
		opacity: 1;
	}
	&:focus {box-shadow: 0 0 4px #cccdce;}
}
input[type=radio],
input[type=checkbox] {
	margin: 0;
	vertical-align: -1px;
}
input[type="button"],
input[type="submit"],
button {
	-webkit-appearance: none;
	cursor: pointer;
	font: inherit;
	vertical-align: bottom;
}
button {border: none;}
textarea {
	resize: vertical;
	vertical-align: bottom;
}
sup {vertical-align: super;}
sub {vertical-align: sub;}
blockquote{
	background-color: #f5f5f5;
	padding: 1em 1em 1em 3em;
	position: relative;
	border-left: 3px solid #666;
}
blockquote:before{
	content: "“";
	font-size: 4em;
	line-height: 1;
	color: #999;
	position: absolute;
	left: 0.15em;
	top: 0.15em;
}
::selection {
	color: #fff;
	background-color: $_COLOR_SELECTION;
}
::-moz-selection {
	color: #fff;
	background-color: $_COLOR_SELECTION;
}

// devaice show-hide class
.l-wrap .is-pc {
	display:block;
	@include tblsp {display: none;}
}
.l-wrap .is-pctbl {
	display:block;
	@include sp {display: none;}
}
.l-wrap .is-tbl {
	display:none;
	@include tbl {display: block;}
}
.l-wrap .is-tblsp {
	display:none;
	@include tblsp {display: block;}
}
.l-wrap .is-sp {
	display:none;
	@include sp {display: block;}
}

.l-wrap .is-pcI {
	display:inline;
	@include tblsp {display: none;}
}
.l-wrap .is-pcSI {
	display:none;
	@include pcS {display: inline;}
}
.l-wrap .is-pctblI {
	display:inline;
	@include sp {display: none;}
}
.l-wrap .is-tblI {
	display:none;
	@include tbl {display: inline;}
}
.l-wrap .is-tblspI {
	display:inline;
	@include pc {display: none;}
}
.l-wrap .is-spI {
	display:none;
	@include sp {display: inline;}
}

// util class
.is-mt0 {margin-top: 0!important;}
.is-mb0 {margin-bottom: 0!important;}
.is-mtXXL {
	margin-top: 6em!important;
	@include sp {margin-top: 4em!important;}
}
.is-mtXL {
	margin-top: 4em!important;
	@include sp {margin-top: 3em!important;}
}
.is-mtL {
	margin-top: 2.5em!important;
	@include sp {margin-top: 1.8em!important;}
}
.is-mtM {
	margin-top: 1.75em!important;
	@include sp {margin-top: 1.5em!important;}
}
.is-mtS {margin-top: 1.25em!important;}
.is-mtXS {margin-top: 0.65em!important;}
.is-taC {text-align: center!important;}
.is-taR {text-align: right!important;}
.is-fwB {font-weight: bold!important;}
.is-tdUL {text-decoration: underline!important;}
.is-colorEm {color: $_COLOR_EM!important;}
.is-colorG {color: #707070!important;}

.is-lhL  {line-height: $_LINE_HEIGHT_L!important;}
.is-lhM  {line-height: $_LINE_HEIGHT!important;}
.is-lhS  {line-height: $_LINE_HEIGHT_S!important;}
.is-lhXS {line-height: $_LINE_HEIGHT_XS!important;}

.l-wrap .is-fsXXL {
	@include fs(24);
	@include sp {
		@include vw(21);
	}
}
.l-wrap .is-fsXL {
	@include fs(18);
	@include sp {
		@include vw(18);
	}
}
.l-wrap .is-fsL {
	@include fs(16);
	@include sp {
		@include vw(16);
	}
}
.l-wrap .is-fsM {
	@include fs(14);
	@include sp {
		@include vw(14);
	}
}
.l-wrap .is-fsS {
	@include fs(12);
	@include sp {
		@include vw(12);
	}
}
.l-wrap .is-fsXS {
	@include fs(11);
	@include sp {
		@include vw(10);
	}
}
