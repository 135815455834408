@charset "utf-8";

//-------------------- main visual

.top-mv-wrap {
	position: relative;
	overflow: hidden; //IE11

	&::after {
		content: "";
		display: block;
		box-sizing: border-box;
		width: 17px;
		height: 17px;
		border-right: 3px solid #5b6ec1;
		border-bottom: 3px solid #5b6ec1;
		position: absolute;
		left: 50%;
		bottom: 6%;
		transform: rotate(45deg) translate3d(-25%, 50%, 0);
		z-index: 3;
		@include sp {
			border-width: 0.125rem;
			height: 0.625rem;
			width: 0.625rem;
		}
	}
}
.top-mv {
	@include pcL {max-height: 820px;}
	// opacity: 0;
	// &.slick-initialized {opacity: 1;}
}
.top-mv-txt {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 3;

	transform: translate3d(0, -50%, 0);
	text-align: center;

	color: $_COLOR_HEADLINE;
	font-weight: bold;
	line-height: $_LINE_HEIGHT_S;
	// font-feature-settings: "palt";
	letter-spacing: 0.1em;

	@include fs(56);
	@include sp {
		@include vw(40);
	}
	&__large {
		@include fs(72);
		@include sp {
			@include vw(48);
		}
	}
	&__small {
		@include fs(48);
		@include sp {
			@include vw(36);
		}
	}
}


.top-lead {
	line-height: $_LINE_HEIGHT;
	margin-bottom: 1rem;
	@include fs(24);
	@include sp {
		@include vw(18);
	}
	&.is-center {
		text-align: center;
	}
	&.is-color-white {
		color: #fff;
	}
}

//-------------------- about business共通

.top-photoblock {
	position: relative;
	&__picture {
		@include pctbl {
			width: 45%;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		@include sp {
			margin-bottom: 2.0rem;
		}
	}
	&__body {
		@include pctbl {
			// max-width: 550px;
			width: 55%;
			position: relative;
			z-index: 2;
			padding-right: 1.5rem;
		}
	}

}


//-------------------- about


//-------------------- business

/**
.top-business {
	position: relative;
	@include sp {padding-bottom: 4rem;}
	&__image {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
		max-width: 845px;
		width: 44%;
		@include tbl {
			width: 37%;
			transform: translateY(-40%);
		}
		@include sp {width: 96vw;}
	}
}
.top-business-anchor {
	margin-bottom: 3rem;
	position: relative;
	@include pc {padding-bottom: 3rem;}
	@include sp {padding-bottom: 90vw;}
	.m-btn {
		@include pctbl {margin-left: 0;}
	}
}
*/

//-------------------- news
/**
.top-news {
	padding-top: 17vw;
	position: relative;
	@include sp {padding-bottom: 2.5rem;}
	> .l-inner {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			background-color: $_COLOR_GRAY;
			top: 0;
			bottom: 0;
			left: 50px;
			width: 150vw;
			z-index: 0;
			@include tbl {left: 30px;}
			@include sp {left: 4vw;}
		}
	}
	.m-title {
		@include pctbl {display: inline-flex;}
		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 115%;
			bottom: 50%;
			width: 90vw;
			height: 0;
			border-top: 1px solid #8192a3;
			@include sp {
				left: 0;
				bottom: -1rem;
			}
		}
	}
	&__image {
		position: absolute;
		left: 0;
		z-index: 0;
		top: 0;
		width: 90vw;
		@include sp {width: 96vw;}
	}
}
.top-news-content {
	max-width: 1350px;
	margin: 0 auto;
	padding: 4rem 50px;
	position: relative;
	z-index: 2;
	@include tbl {padding: 3.5rem 30px;}
	@include sp {
		padding: 2.25rem 6vw 3rem;
	}
}
*/

//-------------------- recruit
.top-recruit {
	position: relative;
	padding-top: 5.5rem;
	padding-bottom: 6.0rem;
	background: transparent url(../images/top/recruit--sp.jpg) no-repeat center center;
	background-size: cover;
	@include pctbl {
		background-image: url(../images/top/recruit.jpg);
	}

	.m-title {
		margin-bottom: 1.5rem;
	}
	.top-lead {
		margin-bottom: 3.375rem;
	}
}

//-------------------- animation
// .js-slideIn {
// 	transform: translateY(3rem);
// 	opacity: 0;
// 	transition: 0.4s ease-out;
// 	&.is-show {
// 		transform: translateY(0);
// 		opacity: 1;
// 	}
// }