@charset "utf-8";

// ブレイクポイント
@mixin pcL {
	@media print, screen and (min-width: 1601px) {
		@content;
	}
}
@mixin pcM {
	@media print, screen and (min-width: $_BREAK_POINT_TB+1) and (max-width: 1600px) {
		@content;
	}
}
@mixin pcS {
	@media print, screen and (min-width: $_BREAK_POINT_TB+1) and (max-width: 1400px) {
		@content;
	}
}
@mixin pcXS {
	@media print, screen and (min-width: $_BREAK_POINT_TB+1) and (max-width: 1260px) {
		@content;
	}
}
@mixin pc {
	@media print, screen and (min-width: $_BREAK_POINT_TB+1) {
		@content;
	}
}
@mixin pc1x { //bg-image
	@media only screen and (min-width: $_BREAK_POINT_TB+1) and (-webkit-min-device-pixel-ratio: 0.5) {
		@content;
	}
	@media (min-width: $_BREAK_POINT_TB+1) and (-ms-high-contrast: none) { //IE11 huck
		@content;
	}
}
@mixin pc2x { //bg-image
	@media only screen and (min-width: $_BREAK_POINT_TB+1) and (-webkit-min-device-pixel-ratio: 2) {
		@content;
	}
}
@mixin pctbl {
	@media print, screen and (min-width: $_BREAK_POINT+1) {
		@content;
	}
}
@mixin tbl {
	@media print, screen and (min-width: $_BREAK_POINT+1) and (max-width: $_BREAK_POINT_TB) {
		@content;
	}
}
@mixin tblsp {
	@media print, screen and (max-width: $_BREAK_POINT_TB) {
		@content;
	}
}
@mixin sp {
	@media print, screen and (max-width: $_BREAK_POINT) {
		@content;
	}
}

// フォント設定 (rem)
@mixin fs($size) {
	font-size: $size+px;
	font-size: ($size / $_FONT_SIZE) * 1rem;
}
// フォント設定 (vw)
@mixin vw($size, $width:$_DESIGN_WIDTH) {
	font-size:( $size ) *1px;
	font-size:( $size / $width * 100 ) *1vw;
}

// l-inner
@mixin inner {
	width: 100%;
	max-width: $_INNER_WIDTH;
	margin-left: auto;
	margin-right: auto;
	padding-left: $_PADDING_PC;
	padding-right: $_PADDING_PC;
	position: relative;
	z-index: 1;
	@include tbl {
		padding-left: $_PADDING_TB;
		padding-right: $_PADDING_TB;
	}
	@include sp {
		padding-left: $_PADDING_SP;
		padding-right: $_PADDING_SP;
	}
}
